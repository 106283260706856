import './Graph.component.scss';
import React, { useEffect, useState } from "react";
import { useImmer } from 'use-immer';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJs } from 'chart.js/auto'
import moment from 'moment';
import * as graphService from '../../Shared/Services/graph/graph.service';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@mui/material';
import GraphCalculationComp from '../graphCalculation/graphCalculationComponent.js'

Interaction.modes.myCustomMode = function (chart, e) {
    const position = getRelativePosition(e, chart);
    let flag = false;
    const items = [];
    Interaction.evaluateInteractionItems(chart, ' ', position, (element, datasetIndex, index) => {
        if (element.x > (position.x - 1) && element.x < (position.x + 1)) {
            if (element.inYRange(position.y)) flag = true;
            items.push({ element, datasetIndex, index })
        };
    });
    return flag ? items : [];
};

let tickObj = []
for (let i = 1; i <= 24; i++) {
    for (let j = 1; j <= 60; j++) {
        tickObj.push(i * 60 + (j - 1));
    }
}

function GraphComponent(props) {
    const { checkList, setCheckList, startDate, setStartDate, searchDate, setSearchDate, currentTimeTrigger, isDarkMode } = props;
    const today = moment().format("YYYY-MM-DD");
    const [data, setData] = useImmer({ actual: [], dayAgo: [], weekAgo: [], awardload: [], bundledLoad: [], da: [], do8am: [] });
    const [graphData, setGraphData] = useImmer({ labels: [], datasets: [] });
    const [restart, setRestart] = useState(0)
    const [warningDialog, setWarningDailog] = useState(false);
    const graphStyle = {
        responsive: true,
        scales: {
            x: {
                border: {
                    color: '#B0B0B0'
                },
                ticks: {
                    callback: (index) => {
                        let tick = tickObj[index] / 60;
                        if (parseInt(tick) === parseFloat(tick)) return (tick);
                    }
                },
                title: {
                    display: true,
                    text: 'Hour'
                },
                grid:{
                    color:'#7A7A7A'
                }
            },
            y: {
                border: {
                    color: '#B0B0B0'
                },
                title: {
                    display: true,
                    text: 'Load'
                },
                grid:{
                    color:'#7A7A7A'
                }
            },
            
        },
        interaction: {
            intersect: true,
            mode: 'myCustomMode'
        },
        plugins: {
            legend: {
                position: 'bottom',
                onClick: () => { }
            },
            title: {
                display: true,
                text: `Last Refresh Time ${(searchDate !== today) ? modifyStartDate(startDate) : modifyToday()}`,
                font: {
                    weight: 'bold'
                }
            },
            tooltip: {
                filter: (tooltipItem, data, tooltipItemArray) => {
                    if (tooltipLogic(tooltipItem, tooltipItemArray) || (tooltipItem.dataset.label === 'Day Ahead' || tooltipItem.dataset.label === 'Awarded Load' || tooltipItem.dataset.label === 'Bundeled Load' || tooltipItem.dataset.label === 'DO 8AM')) {
                        return tooltipItem;
                    };       
                },
                callbacks: {
                    title: (tooltipItem) => {
                        let index = tooltipItem[0].label
                        return (Math.floor(index / 60));
                    }
                }
            }
        }
    }

    function tooltipLogic(tooltipItem, tooltipItemArray) {
        let flag = false;
        let arr1499 = tooltipItemArray.filter((e) => (e.label === '1499'))
        let arr = tooltipItemArray.filter((e) => (e.dataset.label === 'Day Ahead' || e.dataset.label === 'Awarded Load' || e.dataset.label === 'Bundeled Load' || e.dataset.label === 'DO 8AM'))
        if (arr.length > 0) {
            if (tooltipItem.label === arr[0].label) {
                flag = true;
            }
        }
        else{
            if(tooltipItem.label==="1499"){
                flag = true;
            }
            else{
                if(arr1499.length === 0 && tooltipItem.label === tooltipItemArray[0].label) flag = true;
            }
        }
        return flag;
    }

    function modifyStartDate(startDate) {
        return (moment(startDate).format("MM/DD/yyyy") + " 11:57:00 PM");
    }
    function modifyToday() {
        let dur = moment.duration(2, 'minutes');
        return moment(new Date()).subtract(dur).format("MM/DD/yyyy hh:mm:00 A");
    }

    function loadGraph() {
        let req = {
            actual: checkList.actual,
            weekAgo: checkList.weekAgo,
            dayAgo: checkList.dayAgo,
            startDate: startDate
        }
        graphService.getGraphForActualDayAgoWeekAgo(req).then((response) => {
            if (response.data.actual === null) response.data.actual = [];
            if (response.data.dayAgo === null) response.data.dayAgo = [];
            if (response.data.weekAgo === null) response.data.weekAgo = [];
            setData((d) => {
                d.actual = response.data.actual;
                d.dayAgo = response.data.dayAgo;
                d.weekAgo = response.data.weekAgo;
            });
        });

        let req2 = {
            awardedLoad: checkList.awardedLoad,
            bundledLoad: checkList.bundledLoad,
            da: checkList.da,
            startDate: startDate
        }
        graphService.getGraphDataLoad(req2).then((response) => {
            if (response.data.awardload === null) response.data.awardload = [];
            if (response.data.bundledLoad === null) response.data.bundledLoad = [];
            if (response.data.da === null) response.data.da = [];
            setData((d) => {
                d.awardload = response.data.awardload;
                d.bundledLoad = response.data.bundledLoad;
                d.da = response.data.da;
            });
        });

        let req3 = {
            do: checkList.do8am,
            startDate: startDate
        }
        graphService.getGraphDO8DataLoad(req3).then((response) => {
            if (response.data.do === null) response.data.do = [];
            setData((d) => { d.do8am = response.data.do });
        })
    }

    function handleLoad() {
        setSearchDate(startDate);
        loadGraph();
        setWarningDailog(true);
    }

    useEffect(() => {
        loadGraph();
    }, [checkList, isDarkMode, currentTimeTrigger]);

    useEffect(() => {
        let timeId;
        if (moment(searchDate).format("YYYY-MM-DD") === today) {
            timeId = setInterval(() => {
                loadGraph();
                graphStyle.plugins = {
                    title: {
                        display: true,
                        text: `Last Refresh Time ${(searchDate !== today) ? modifyStartDate(startDate) : modifyToday()}`,
                        font: {
                            weight: 'bold'
                        }
                    }
                }
                setRestart(restart + 1)
            }, 120000);
        }
        return () => { if (timeId) clearInterval(timeId) }
    }, [data]);

    useEffect(() => {
        let gData = {
            labels: tickObj,
            datasets: []
        };
        const actualObj = {
            label: 'Actual',
            data: data.actual.map((e) => ({ x: (e.minute + 60), y: e.value })),
            borderColor: isDarkMode?"yellow":"olive",
            backgroundColor: isDarkMode?"yellow":"olive",
            fill: false,
            tension: 0.1,
            pointStyle: false,

        };
        const dayAgoObj = {
            label: 'Day Ago',
            data: data.dayAgo.map((e) => ({ x: (e.minute + 60), y: e.value })),
            borderColor: isDarkMode?"hotpink": 'deeppink',
            backgroundColor: isDarkMode?"hotpink": "deeppink",
            fill: false,
            tension: 0.1,
            pointStyle: false
        };
        const weekAgoObj = {
            label: 'Week Ago',
            data: data.weekAgo.map((e) => ({ x: (e.minute + 60), y: e.value })),
            borderColor: 'darkviolet',
            backgroundColor: "darkviolet",
            fill: false,
            tension: 0.1,
            pointStyle: false
        };
        const aLObj = {
            label: 'Awarded Load',
            data: data.awardload.map((e, i) => ((i < 24) ? { x: ((e.hrs * 60) + 30), y: e.mw } : { x: null, y: null })),
            borderColor: isDarkMode?"aqua": 'blue',
            backgroundColor: isDarkMode?"aqua": "blue",
            fill: false,
            tension: 0.1,
            pointStyle: false
        };
        const bLObj = {
            label: 'Bundled Load',
            data: data.bundledLoad.map((e, i) => ((i < 24) ? { x: ((e.hrs * 60) + 30), y: e.mw } : { x: null, y: null })),
            borderColor: isDarkMode?"orange": 'chocolate',
            backgroundColor: isDarkMode?"orange": "chocolate",
            fill: false,
            tension: 0.1,
            pointStyle: false
        };
        const daObj = {
            label: 'Day Ahead',
            data: data.da.map((e, i) => ((i < 24) ? { x: ((e.hrs * 60) + 30), y: e.mw } : { x: null, y: null })),
            borderColor: 'red',
            backgroundColor: "red",
            fill: false,
            tension: 0.1,
            pointStyle: 'rectRot',
            pointRadius: 5,
        };
        const do8amObj = {
            label: 'DO 8AM',
            data: data.do8am.map((e, i) => ((i < 24) ? { x: ((e.hrs * 60) + 30), y: e.mw } : { x: null, y: null })),
            borderColor: isDarkMode?"springgreen": "teal",
            backgroundColor: isDarkMode?"springgreen": "teal",
            fill: false,
            tension: 0.1,
            pointStyle: false,
        };
        if (checkList.actual && (data.actual.length !== 0)) gData.datasets.push(actualObj);
        if (checkList.dayAgo && (data.dayAgo.length !== 0)) gData.datasets.push(dayAgoObj);
        if (checkList.weekAgo && (data.weekAgo.length !== 0)) gData.datasets.push(weekAgoObj);
        if (checkList.awardedLoad && (data.awardload.length !== 0)) gData.datasets.push(aLObj);
        if (checkList.bundledLoad && (data.bundledLoad.length !== 0)) gData.datasets.push(bLObj);
        if (checkList.da && (data.da.length !== 0)) gData.datasets.push(daObj);
        if (checkList.do8am && (data.do8am.length !== 0)) gData.datasets.push(do8amObj);
        setGraphData(gData);
    }, [data]);

    return (
        <>
            <div className='start_date'>
                <input type="date" max={moment().format("YYYY-MM-DD")} value={startDate} onChange={(e) => { (e.target.value == "") ? setStartDate(today) : setStartDate(e.target.value) }}></input>
                <Button variant="contained" size="medium" style={{ margin: "0 5px" }} onClick={handleLoad}>Refresh</Button>
            </div>
            <div className='main_container'>
                <GraphCalculationComp restart={restart} checkList={checkList} searchDate={searchDate} startDate={startDate} currentTimeTrigger = {currentTimeTrigger}></GraphCalculationComp>
                <div className='graph_container'>
                    <Line data={graphData} options={graphStyle} />
                    <hr />
                    <div className='footer'>
                        <div style={{ display: "flex" }}>
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'actual':'actualDark'} checked={checkList.actual} onChange={(e) => { setCheckList((draft) => { draft.actual = e.target.checked }) }} />
                                <span>Actual</span>
                            </div>
                            &nbsp;&nbsp;
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'dayAgo':'dayAgoDark'} checked={checkList.dayAgo} onChange={(e) => { setCheckList((draft) => { draft.dayAgo = e.target.checked }) }} />
                                <span>Day Ago</span>
                            </div>
                            &nbsp;&nbsp;
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'weekAgo':'weekAgoDark'} checked={checkList.weekAgo} onChange={(e) => { setCheckList((draft) => { draft.weekAgo = e.target.checked }) }} />
                                <span>Week Ago</span>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'do8am':'do8amDark'} checked={checkList.do8am} onChange={(e) => { setCheckList((draft) => { draft.do8am = e.target.checked }) }} />
                                <span>DO 8AM</span>
                            </div>
                            &nbsp;&nbsp;
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'da':'daDark'} checked={checkList.da} onChange={(e) => { setCheckList((draft) => { draft.da = e.target.checked }) }} />
                                <span>DA</span>
                            </div>
                            &nbsp;&nbsp;
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'awardedLoad':'awardedLoadDark'} checked={checkList.awardedLoad} onChange={(e) => { setCheckList((draft) => { draft.awardedLoad = e.target.checked }) }} />
                                <span>Awarded Load</span>
                            </div>
                            &nbsp;&nbsp;
                            <div className='chk_txt'>
                                <input type="checkbox" id={!isDarkMode?'bundeledLoad':'bundeledLoadDark'} checked={checkList.bundledLoad} onChange={(e) => { setCheckList((draft) => { draft.bundledLoad = e.target.checked }) }} />
                                <span>Bundled Load</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                warningDialog ?
                    <Dialog sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "50%",
                                maxWidth: "250px",  // Set your width here
                                height: "50%",
                                maxHeight: "100px"
                            }
                        }
                    }} open={warningDialog}>
                        <DialogContent>
                            <div>Data loading completed for: {moment(startDate).format("MM/DD/YYYY")}!</div>
                        </DialogContent>
                        <DialogActions>
                            <button data-testid="reject" onClick={() => { setWarningDailog(false) }} style={{ float: "right", width: "50px", height: "20px" }} >OK</button>
                        </DialogActions>
                    </Dialog> : null
            }
        </>
    )
}

export default GraphComponent;