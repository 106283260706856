import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

function CanActivate(props){
    const navigate = useNavigate()
    const { instance, accounts } = useMsal()

    const refreshToken = () => {
      return instance.acquireTokenSilent({
        scopes: [process.env.REACT_APP_SCOPE],
        account: accounts[0]
      })
    }

    axios.interceptors.response.use(
      res =>{
        props.setter(true)
        navigate("/")
        return res
      }, 
      err =>{
        let request = err.config;
        if(err.response.status===401 && !request._retry){
          request._retry = true;
          return refreshToken().then(res =>{
            localStorage.setItem('token', res.accessToken)
            return axios(request)
          })
        }
        else if(err.response.status===403){ 
          props.setter(false)
          navigate("/unauthorized")
          Promise.reject('Access Blocked!')
        }
        else return Promise.reject(err)
      }
    )
}

export default CanActivate;