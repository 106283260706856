import Router from './navigation/router';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Restricted from './Pages/Restricted/restricted';
import LogIn from './Shared/Services/login/login.service';
import { useState } from 'react';

function App({msalInstance}) {
  const [ loggedIn, setLoggedIn ] = useState()

  return (
    <MsalProvider instance={msalInstance}>
        <LogIn setter={setLoggedIn} />

        <AuthenticatedTemplate>
          {loggedIn && <Router />}
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Restricted userState="unauthenticated"/>
        </UnauthenticatedTemplate>
         
    </MsalProvider>
    
  );
}

export default App;
