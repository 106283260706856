import { Button, MenuItem, Box,Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import './graphCalculationComponent.scss';
import React, { useEffect, useState } from "react";
import { useImmer } from 'use-immer';
import moment from 'moment';
import * as graphCalService from '../../Shared/Services/graphCalculation/graphCalculation.service.js';


let tableData = [
    {
        "Duration": "1 Min",
        "Accel": "0.00",
        "Delta Accel": "0.00",
    },
    {
        "Duration": "10 Min",
        "Accel": "0.00",
        "Delta Accel": "0.00",
    },
    {
        "Duration": "30 Min",
        "Accel": "0.00",
        "Delta Accel": "0.00",
    }
]
let tableDaActual = [
    {
        "Item": "Curr. MW Delta",
        "Value": "NA",
    },
    {
        "Item": "Simple % Error",
        "Value": "NA",
    },
    {
        "Item": "MAPE",
        "Value": "NA",
    }
]

let tableActual = [
    {
        "Heading": "Actual-Current",
        "Value1": "0",
        "Value2": "",
    },
    {
        "Heading": "Actual-Today Peak",
        "Value1": "0",
        "Value2": "00:00",
    },
    {
        "Heading": "Yesterday Peak",
        "Value1": "11,529",
        "Value2": "17:58",
    },
    {
        "Heading": "Weak-Ago Peak",
        "Value1": "0",
        "Value2": "00:00",
    },
    {
        "Heading": "DO Peak",
        "Value1": "NA",
        "Value2": "NA",
    },
    {
        "Heading": "DA Peak",
        "Value1": "0",
        "Value2": "00:00",
    },
    {
        "Heading": "Tomorrow Peak",
        "Value1": "NA",
        "Value2": "NA",
    }
]

export default function GraphCalculationComp(props) {
    const {searchDate,restart,checkList, startDate, currentTimeTrigger}=props
    const [tablePeakData,setTablePeakData]=useImmer([])
    const [tableAccelData,setAccelData]=useImmer([])
    const [tableDAVsActualData,setDAVsActualData]=useImmer([])
    const [vendorDropdownData,setVendorDropdownData]=useState([])
    const [stationDropdownData,setStationDropdownData]=useState([])
    const [tempForm,setTempForm]=useImmer({
        "startDate": !currentTimeTrigger? searchDate : startDate,
        "vendor": "",
        "station": ""
      })
    const [tempDetails,setTempDetails]=useState({
        "miN_VALUE": null,
        "maX_VALUE": null
      })
    const [tableDOVsActualData,setTableDOVsActualData]=useImmer([])
    const [priceInfoData,setPriceInfoData]=useState({
        "daLmp": null,
        "daLmpTime": null,
        "damCost": null,
        "fmmValue": null,
        "fmmTime": null,
        "instNetPosition": null
      })

    
    

    function loadAccelerationData(){
        let req={
            "startDate":moment(!currentTimeTrigger? searchDate : startDate).format("YYYY-MM-DD")
        }
        setAccelData([])   
        graphCalService.getAccelerationData(req).then((response)=>{
            let data=response.data;
            setAccelData(data)
        })

    }
    function loadTempDropDownData(){
        graphCalService.getVendorDropdownData().then((result)=>{
            setVendorDropdownData(result.data)
            setTempForm(draft=>{
                draft.vendor= result.data[0].vendor;
            })
        })
        graphCalService.getStationDropdownData().then((result)=>{
            setStationDropdownData(result.data)
            setTempForm(draft=>{
                draft.station= result.data[0].station;
            })
        })
    }
    function loadDAVsActual(){
        let req={
            "actualLdDate":moment(!currentTimeTrigger? searchDate : startDate).format("YYYY-MM-DD")
        }
        setDAVsActualData([])
        graphCalService.getDAvsActualData(req).then((response)=>{
            setDAVsActualData(response.data)
        })
    }

    function loadTempData(){
        graphCalService.getTemperatureData(tempForm).then((result)=>{
            if(result.data[0])
                setTempDetails(result.data[0])
            else
                setTempDetails({
                    "miN_VALUE": null,
                    "maX_VALUE": null
                  })
        })
    }
    function loadDovsActual(){
        let req={
            "actualLdDate":moment(!currentTimeTrigger? searchDate : startDate).format("YYYY-MM-DD"),
            "do": checkList.do8am            
        }
        setTableDOVsActualData([])      
        graphCalService.getDOvsActualData(req).then(result=>{
            setTableDOVsActualData(result.data)
        }).catch(error=>{
        })
    }
    function loadPriceInfo(){
        let req={
            "actualLdDate":moment(!currentTimeTrigger? searchDate : startDate).format("YYYY-MM-DD"),            
        }
        graphCalService.getPriceInfoData(req).then(result=>{
            setPriceInfoData(result.data)
        }).catch(error=>{

        })
    }

    function loadPeakData(){
        let req={
            "startDate":moment(!currentTimeTrigger? searchDate : startDate).format("YYYY-MM-DD")
        }
        setTablePeakData([])
        graphCalService.getPeakValuesData(req).then((response)=>{
            let result=response.data
            let k=[
                {
                    "Heading": "Actual-Current",
                    "Value1": result.actual_Current!=null?result.actual_Current:"NA",
                    "Value2": "",
                },
                {
                    "Heading": "Actual-Today Peak",
                    "Value1": result.actual_Today_Peak!=null?result.actual_Today_Peak:"NA",
                    "Value2": result.actual_Today_PeakTime!=null?moment(result.actual_Today_PeakTime).format("HH:mm").substring():"NA",
                },
                {
                    "Heading": "Yesterday Peak",
                    "Value1": result.yesterday_Peak!=null?result.yesterday_Peak:"NA",
                    "Value2": result.yesterday_PeakTime!=null?moment(result.yesterday_PeakTime).format("HH:mm"):"NA",
                },
                {
                    "Heading": "Week-Ago Peak",
                    "Value1": result.weekAgo_Peak!=null?result.weekAgo_Peak:"NA",
                    "Value2": result.weekAgo_PeakTime!=null?moment(result.weekAgo_PeakTime).format("HH:mm"):"NA",
                },
                {
                    "Heading": "DO Peak",
                    "Value1": result.dO_Peak!=null?result.dO_Peak:"NA",
                    "Value2": result.dO_PeakTime!=null?result.dO_PeakTime+":00":"NA",
                },
                {
                    "Heading": "DA Peak",
                    "Value1": result.dA_Peak!=null?result.dA_Peak:"NA",
                    "Value2": result.dA_PeakTime!=null?result.dA_PeakTime+":00":"NA",
                },
                {
                    "Heading": "Tomorrow Peak",
                    "Value1": result.tmrw_Peak!=null?result.tmrw_Peak:"NA",
                    "Value2": result.tmrw_PeakTime!=null?moment(result.tmrw_PeakTime).format("HH:mm"):"NA",
                }
            ]
            setTablePeakData(k)
        })
    }
    function getlabel(val){
        if(val<0){
            return "($"+(val*-1).toLocaleString(undefined,{maximumFractionDigits:0})+")";
        }
        return "$"+val.toLocaleString(undefined,{maximumFractionDigits:0})
    }
    useEffect(()=>{
        loadTempDropDownData()
    },[])

    useEffect(()=>{
        if(tempForm.vendor!="" && tempForm.station!="")
            loadTempData()
    },[tempForm])

    useEffect(()=>{
        loadDovsActual()
    },[searchDate,restart,checkList.do8am,currentTimeTrigger])

    useEffect(()=>{
        loadPeakData()
        loadAccelerationData()
        loadDAVsActual()
        loadPriceInfo()
        setTempForm(draft=>{
            draft.startDate= !currentTimeTrigger? searchDate : startDate;
        })
    },[searchDate,restart, currentTimeTrigger])

    return   <div className='divClass'>
                {/* <div className='tableClass'> */}
                    <div className="priceInfoGrid">
                        <div className="innerGrid1">
                            <div className="div1">DAM Hourly LMP <br/><br/>${priceInfoData.daLmp!=null?(priceInfoData.daLmp.toLocaleString(undefined,{maximumFractionDigits:2})):"NA"} <br/> [HE:{priceInfoData.daLmpTime!=null?priceInfoData.daLmpTime:"NA"}]</div>
                            <div className="div2">Inst Net Position <br/><br/> {priceInfoData.instanteneousPosition!=null?priceInfoData.instanteneousPosition:"NA"}</div>
                            <div className="div3">FMM 15-min LMP<br/><br/>${priceInfoData.fmmValue!=null?(priceInfoData.fmmValue.toLocaleString(undefined,{maximumFractionDigits:2})):"NA"} <br/> {priceInfoData.fmmTime!=null?priceInfoData.fmmTime:"NA"}</div>
                            <div className="div4">Inst $ Impact<br/><br/> {priceInfoData.instImpact!=null?(priceInfoData.instImpact):"NA"}</div>
                        </div>
                        <div className="innerGrid2">Total $ Impact <br/><br/><br/> DAM Cost <br/> {priceInfoData.damCost!=null?getlabel(priceInfoData.damCost):"NA"} <br/> <br/> RTM Cost <br/> {priceInfoData.rtmCost!=null?getlabel(priceInfoData.rtmCost):"NA"}</div>
                    </div>
                <br></br>
                <div className='tableClass' style={{width: "290px"}}>
                    <Table>
                        <TableHead className="headStyle">
                            <TableRow>
                                <TableCell>Duration</TableCell>
                                <TableCell>Accel.</TableCell>
                                <TableCell>Delta Accel.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableAccelData.map((item) =>(
                                <TableRow key={item.Duration}>
                                    {Object.values(item).map((val) =>(
                                        <TableCell>{val}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <br></br>
                <div className='tableClass' style={{width: "290px"}}>
                    <div className="boldText">DA vs Actual</div> 
                    <Table>
                        <TableHead className="headStyle">
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableDAVsActualData.map((item) =>(
                                <TableRow key={item.Item}>
                                    {Object.values(item).map((val) =>(
                                        <TableCell>{val}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <br></br>
                <div className='tableClass' style={{width: "290px"}}>
                    <div className="boldText">DO vs Actual</div> 
                    <Table>
                        <TableHead className="headStyle">
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableDOVsActualData.map((item) =>(
                                <TableRow key={item.Item}>
                                    {Object.values(item).map((val) =>(
                                        <TableCell>{val}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <br/>
                <div className='tableClass' style={{width: "290px"}}>
                    <Table>
                        <TableBody>
                            {tablePeakData.map((item) =>(
                                <TableRow key={item.Heading}>
                                    {Object.values(item).map((val) =>(
                                        <TableCell>{typeof(val)=="number"?val.toLocaleString(undefined,{maximumFractionDigits:0}):val}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <br/>
                <div className='tableClass' style={{width: "290px"}}>
                    <div className="boldText">Temperature Forecast</div> 
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Vendor
                                    <select style={{marginLeft:"3px",padding:"4px"}}  value={tempForm.vendor} onChange={(event)=>{setTempForm(draft=>{draft.vendor= event.target.value;})}}>
                                        {vendorDropdownData.map(item=>{
                                            return <option key={item.vendor} value={item.vendor}>{item.vendor}</option>
                                        })}
                                    </select>
                                </TableCell>
                                <TableCell>
                                    Station
                                    <select style={{marginLeft:"3px",padding:"4px"}} value={tempForm.station} onChange={(event)=>{setTempForm(draft=>{draft.station= event.target.value;})}}>
                                        {stationDropdownData.map(item=>{
                                            return <option key={item.station} value={item.station}>{item.station}</option>
                                        })}
                                    </select>
                                </TableCell>
                                
                            </TableRow>
                            <TableRow>
                                <TableCell>MIN: {tempDetails.miN_VALUE!=null?tempDetails.miN_VALUE:"NA"}</TableCell>
                                <TableCell>MAX: {tempDetails.maX_VALUE!=null?tempDetails.maX_VALUE:"NA"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>

}