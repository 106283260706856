import axios from "axios";

export function getGraphForActualDayAgoWeekAgo(req) {
    return axios.post('GraphMinLoad/GetGraphForActualDayAgoWeekAgo', req);
}

export function getGraphDataLoad(req) {
    return axios.post('GraphTab/GetGraphDataLoad', req);
}

export function getGraphDO8DataLoad(req) {
    return axios.post('GraphTab/GetGraphDO8DataLoad', req);
}

