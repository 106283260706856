import XLSX from "sheetjs-style";

const ExportToCsv = (excelData, header, fileName) => {
  const fileExtenstion = ".csv";
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, [header])
  XLSX.utils.sheet_add_json(ws, excelData, {origin: 'A2', skipHeader: true});
  XLSX.utils.book_append_sheet(wb, ws, 'data')
  XLSX.writeFile(wb, fileName+fileExtenstion)
};

export { ExportToCsv };
