import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeComponent from '../Pages/home/home.component';
import Restricted from '../Pages/Restricted/restricted';
import CanActivate from '../Shared/AuthGuard/auth.guard';
import { useState } from 'react';

function Router(){
    const [activate, setActivate] = useState(true)
    return(
        <BrowserRouter>
            <CanActivate setter={setActivate}/>
            <Routes>
                <Route path="/" index element={activate && <HomeComponent/>}/>
                <Route path="/unauthorized" element={<Restricted userState="unauthorized"/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Router
