import moment from "moment";
import "./data.tab.component.scss";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { GetMinuteLoad, GetHourlyLoad } from "../../Shared/Services/home/home.service";
import CustomTableComponent from "./custom.table.component";
import { ExportToCsv } from "../../Shared/Services/csv/csv.service";
import Spinner from '../spinner/spinner.component';

function DataTabComponent() {
  const [clock, setClock] = useState(moment().format("YYYY-MM-DD HH:mm:ss"))
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [days, setDays] = useState(4);
  const [minuteData, setMinuteData] = useState({});
  const [hourlyData, setHourlyData] = useState({});
  const [errors, setErrors] = useState("");

  //ref variable for not displaying alert on initial load
  const clicked = useRef(0);
  //format dateTime to date
  const getDate = () => moment(clock).format("YYYY-MM-DD")

  useEffect(()=>{
    let id = setTimeout(()=>{
      let d = moment().format("YYYY-MM-DD HH:mm:ss") 
      setClock(d)
    }, 60000)
    //check for midnight and current date
    if( moment(clock).format("HH:mm")==='00:00' && moment(currentDate).add(1,'d').format('YYYY-MM-DD')===getDate()){
      setCurrentDate(getDate())
      handleRefresh(null, getDate())
    }
    //clear timeout on component unmount and on following renders
    return ()=>clearTimeout(id)
  }, [ clock ])

  //handle days change 
  function handleDaysChange(e){
      setDays(e.target.value);
      if (!e.target.validity.valid) setErrors(e.target.validationMessage);
      else setErrors("");
  }

  //handle for refresh button
  function handleRefresh(e, date){
      if(errors){
          alert(errors)
          console.log('Invalid Input!')
      }
      else{
        clicked.current = 0
        GetMinuteLoad({actualLdDate: date?date:currentDate, number: days}, setMinuteData)
        GetHourlyLoad({actualLdDate: date?date:currentDate, number: days}, setHourlyData)
      }
  }

  //display alert on refresh click if no data is present in either case
  useEffect(()=>{
    clicked.current+=1
    if(clicked.current===2 && !(minuteData.data || hourlyData.data)){
      alert('No data found!')
    }
  },[ hourlyData, minuteData ])

  //get initial load on page load
  useEffect(() => {
    handleRefresh()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div data-testid="data-tab-component">
      <div className="menu-bar">
        <label>Selected Date: </label> &nbsp;
        <input
          data-testid="current-date"
          type="date"
          value={currentDate}
          max={getDate()}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
        &emsp;&emsp;&emsp;
        <label>No. of Days: </label> &nbsp;
        <input
          data-testid="days"
          type="number"
          value={days}
          min="1"
          max="5"
          onChange={handleDaysChange}
          style={{ width: "40px" }}
        />
        &emsp;&emsp;&emsp;
        <Button
          variant="contained"
          size="large"
          onClick={handleRefresh}
        >
          Refresh
        </Button>
      </div>
      <div className="container">
        <Spinner url="Get2MinDataLoad" />
        {minuteData.data && hourlyData.data && (
          <CustomTableComponent
            exportHandler={ExportToCsv}
            heading = "2-Min Load"
            header = {minuteData.header}
            data = {minuteData.data}
            fileName="2MinLoad"
          />
        )}
        <Spinner url="GetAvgHrsDataLoad" />
        {minuteData.data && hourlyData.data && (
          <CustomTableComponent
            exportHandler = {ExportToCsv}
            heading = "Hourly Average"
            header = {hourlyData.header}
            data = {hourlyData.data}
            fileName="AverageLoad"
          />
        )}
      </div>
    </div>
  );
}

export default DataTabComponent;
