import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';

//axios defaults and interceptor initialization
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(req =>{
  req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  return req
})

//create theme
const theme = createTheme({
  typography:{
    fontFamily:  `"open-sans",sans-serif`
  }
})

//msal-instance initialization
const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: "https://login.microsoftonline.com/5b2a8fee-4c95-4bdc-8aae-196f8aacb1b6",
    redirectUri: ""
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <ThemeProvider theme={theme}>
      <App msalInstance={pca} />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
