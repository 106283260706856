import React, { useEffect, useState } from 'react';
import Logo from '../../assets/nav-logo-sce-home.svg';
import './home.component.scss';
import { Tab, Tabs, Box } from '@mui/material';
import DataTabComponent from '../data-tab/data.tab.component';
import GraphComponent from '../Graph/Graph.component';
import { useImmer } from 'use-immer';
import moment from 'moment';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Switch from '@mui/material/Switch';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
  

function HomeComponent() {
    const [isDarkMode,setDarkMode]=useState(false)
    const theme = createTheme({
        palette: {
          mode:  isDarkMode?'dark':'light', // handle the dark mode state on toggle
        },
        typography:{
          fontFamily:  `"open-sans",sans-serif`
        }
      })
    const [index, setIndex] = useState(0)
    const [checkList, setCheckList] = useImmer({
        actual: true,
        dayAgo: true,
        weekAgo: true,
        da: true,
        do8am: true,
        awardedLoad: true,
        bundledLoad: true
    });
    const [today, setToday] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [currentTime, setCurrentTime] = useState(moment(new Date()).format());
    const [currentTimeTrigger, setCurrentTimeTrigger] = useState(false);
    const [startDate, setStartDate] = useState(today);
    const [searchDate, setSearchDate] = useState(today);

    // useEffect(()=>{
    //   setTimeout(() => {
    //     setStartDate(moment().format("2024-05-13"));
    //     setToday(moment().format("2024-05-13"));
    //     setCurrentTimeTrigger(!currentTimeTrigger);        
    //   }, 10000);
    // },[]) // for current date testing
    
    useEffect(() => {
      let timeId = setInterval(() => {
          setCurrentTime(moment(new Date()).format());
      }, 55000); 
      if(today !== currentTime.split("T")[0]){ // for any date differance
        setToday(currentTime.split("T")[0]);
        if(moment(startDate).add(1,'d').format('YYYY-MM-DD') === currentTime.split("T")[0]){ // for one date differance
          setStartDate(currentTime.split("T")[0]);
          setCurrentTimeTrigger(!currentTimeTrigger); 
        }        
      }
      return () => { clearInterval(timeId) }
    }, [currentTime])

    return (
        <ThemeProvider theme={theme}>

        <CssBaseline/>
        <div>
            <header>
                <div className='header-lft'>
                    <img alt="sce-logo" src={Logo} />
                    <p className="header-title">DEMAND GRAPH</p>
                </div>
                <div className="arrow-right"></div>
                {/* <IconButton sx={{ ml: 1 ,backgroundColor:isDarkMode?"black":"white"}} onClick={()=>{setDarkMode(!isDarkMode)}} color="inherit">
                    {isDarkMode? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <Switch
                checked={isDarkMode}
                onChange={()=>setDarkMode(!isDarkMode)}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{color:"black"}}
                /> */}
                <div className="themeSwitch">
                    <MaterialUISwitch checked={isDarkMode}
                    onChange={()=>setDarkMode(!isDarkMode)}></MaterialUISwitch>
                    <label htmlFor="" style={{color:"black"}} >{isDarkMode?'Dark Mode':'Light Mode'}</label>
                </div>
                <HomeTab index={index} setIndex={setIndex} />
            </header>
            <div style={{margin: '10px'}}>
                {index===0 && <GraphComponent 
                checkList={checkList} 
                setCheckList={setCheckList} 
                startDate={startDate}
                setStartDate={setStartDate}
                searchDate={searchDate}
                setSearchDate={setSearchDate}
                currentTimeTrigger = {currentTimeTrigger}
                isDarkMode={isDarkMode}/>}
                {index===1 && <DataTabComponent />}
            </div>
        </div>
        </ThemeProvider>

    )
}

export default HomeComponent;


function HomeTab(props){
    const matTabStyles = {
        margin: "0 0.4px",
        fontSize: '14px',
        borderRight: '1px solid gray',
        borderLeft: '1px solid gray',
        backgroundColor: "white",
        height: '60px',
        color:"black"
    }

    function handleChange(e, ind){
        props.setIndex(ind)
    }
        
    return(
        <>
        <div>
            <Box sx={{width: '240px', position: "absolute", right: "40px"}}>
                <Tabs value={props.index} onChange={handleChange} variant="fullWidth" >
                    <Tab label="Graph" sx={matTabStyles} />
                    <Tab label="Data" sx={matTabStyles} />
                </Tabs>
            </Box>
        </div>
        </>
    )
}