import axios from "axios";
import "./spinner.component.scss";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';

function Spinner(props){
    const [ spinner, setSpinner ] = useState('none')

    axios.interceptors.request.use((req)=>{
        if(req.url.includes(props.url)) setSpinner('flex')
        return req
    })

    axios.interceptors.response.use((res)=>{
        if(res.config.url.includes(props.url)) setSpinner('none')
        return res
    })

    return(
    <div id={props.url} className="spinner-container" style={{display: spinner}}>
        <CircularProgress/>
    </div>)
}

export default Spinner;