import axios from "axios";

export function getPeakValuesData(req){
    return axios.post('CalculatedGraphData/GetPeakValuesData', req);    
}
export function getAccelerationData(req){
    return axios.post('CalculatedGraphData/GetAccelerationData', req);    
}
export function getDAvsActualData(req){
    return axios.post('CalculatedGraphData/GetDAvsActualData', req);    
}
export function getVendorDropdownData(req){
    return axios.get('CalculatedGraphData/GetVendorDropdownData');    
}
export function getStationDropdownData(req){
    return axios.get('CalculatedGraphData/GetStationDropdownData');    
}
export function getTemperatureData(req){
    return axios.post('CalculatedGraphData/GetTemperatureData', req);    
}
export function getDOvsActualData(req){
    return axios.post('CalculatedGraphData/GetDOvsActualData', req);    
}
export function getPriceInfoData(req){
    return axios.post('CalculatedGraphData/GetPriceInfoData', req);    
}