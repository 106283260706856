import axios from 'axios';
import moment from 'moment';

function getHeader(req){
    let header = []
    let mnt = moment(req.actualLdDate).subtract(req.number,'d')
    for(let i=1; i<=req.number; i++){
        mnt = mnt.add(1,'d')
        header.push(mnt.format('MM-DD-YYYY'))
    }
    return header
}

//http req/res handler for 2-minute load
function GetMinuteLoad(req, setter){
    return axios.post('DataTab/Get2MinDataLoad', req).then(res=>{
        if(!res.data['finalreturn'+req.number]) setter({})
        setter({
            header: ['HH:MM'].concat(getHeader(req)),
            data: res.data['finalreturn'+req.number]
        })
    }).catch(err=>{
        console.log(err.message)
    })
}


//http req/res handler for hourly average
function GetHourlyLoad(req, setter){
    // return axios.gepostt('/getHourlyLoad').then(res=>{
        
    return axios.post('DataTab/GetAvgHrsDataLoad', req).then(res=>{
        if(!res.data['list'+req.number]) setter({})
        setter({
            header: ['HH'].concat(getHeader(req)),
            data: res.data['list'+req.number]
        })
    }).catch(err=>{
        console.log(err.message)
    })
}


export { GetMinuteLoad, GetHourlyLoad }