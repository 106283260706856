import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import "./data.tab.component.scss";
import Button from "@mui/material/Button";

function CustomTableComponent(props) {
  let sn = 1;
  let color = "";

  function RenderTableBody() {
    return props.data.map((row) => {
      color = color === "white" ? "#F5F5F5" : "white";
      return (
        <TableRow key={sn++}>
          {Object.keys(row).map((key) => (
            <TableCell key={sn++} sx={{ backgroundColor: color,color:"black" }}>
              {row[key]}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  return (
    <div data-testid={props.heading} style={{ width: "calc(50vw - 10px)" }}>
      <h1>{props.heading}</h1>
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow key="0">
              {
                props.header.map(val => <TableCell sx={{ backgroundColor: "white",color:"black" }} key={val}>{val}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>{RenderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        size="large"
        onClick={() => props.exportHandler(props.data, props.header, props.fileName)}
      >
        Export
      </Button>
    </div>
  );
}

export default CustomTableComponent;
